<template>
  <CardToolbar
    @days-changed="fetchNewData"
    title="Radio Airplay"
    description="Radio Plays by country"
  >
    <template v-slot:body>
      <el-row v-loading="loading">
        <el-col :span="12">
          <ListenersTable
            :data="monthlyListeners"
            :table-headers="tableHeaders"
          />
        </el-col>
        <el-col :span="12">
          <BarGraph :data="monthlyListeners" title="No. if monthly listeners" />
        </el-col>
      </el-row>
    </template>
  </CardToolbar>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import CardToolbar from "../../common/components/CardToolbar";
import ListenersTable from "./ListenersTable.vue";
import BarGraph from "./BarGraph";
import { getSpotifyListenersByCountry } from "../../api/artists.api";
export default {
  name: "AirplayOverCountry",
  components: { CardToolbar, ListenersTable, BarGraph },

  setup() {
    const route = useRoute();
    const loading = ref(false);
    const monthlyListeners = ref({ countries: [], followers: [] });
    const tableHeaders = ref(["Rank", "Country", "Listeners"]);

    onMounted(async () => {
      await getSpotifyMonthlyListenersData(route.params.uuid, 7, false);
    });

    async function getSpotifyMonthlyListenersData(uuid, days, isYearData) {
      loading.value = true;
      try {
        const { data } = await getSpotifyListenersByCountry(
          uuid,
          days,
          isYearData
        );
        monthlyListeners.value = {
          countries: data.xAxis,
          followers: data.yAxis,
        };
        loading.value = false;
      } catch (e) {
        console.error(e);
      }
    }

    const fetchNewData = async (days) => {
      await getSpotifyMonthlyListenersData(
        route.params.uuid,
        days,
        days === 12
      );
    };

    return { monthlyListeners, loading, tableHeaders, fetchNewData };
  },
};
</script>

<style scoped></style>
