<template>
  <AirplayOverCountry />
  <AirplayOverTime />
</template>

<script>
import AirplayOverCountry from "../components/AirplayOverCountry";
import AirplayOverTime from "../components/AirplayOverTime";

export default {
  name: "Airplay",
  components: { AirplayOverCountry, AirplayOverTime },
};
</script>

<style scoped></style>
