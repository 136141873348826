<template>
  <CardToolbar
    @days-changed="fetchNewData"
    description="Radio Plays over last 365 days"
    title="Airplay over time"
  >
    <template v-slot:body>
      <el-row v-loading="loading">
        <el-col :span="24">
          <BarGraph :data="monthlyListeners" title="TOTAL PLAYS" />
        </el-col>
      </el-row>
    </template>
  </CardToolbar>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import CardToolbar from "../../common/components/CardToolbar";
import BarGraph from "./BarGraph";
import { getSpotifyListenersByCountry } from "../../api/artists.api";
export default {
  name: "AirplayOverTime",
  components: { CardToolbar, BarGraph },

  setup() {
    const route = useRoute();
    const loading = ref(false);
    const monthlyListeners = ref({ countries: [], followers: [] });

    onMounted(async () => {
      await getAirplayOverTime(route.params.uuid, 7, false);
    });

    async function getAirplayOverTime(uuid, days, isYearData) {
      loading.value = true;
      try {
        const { data } = await getSpotifyListenersByCountry(
          uuid,
          days,
          isYearData
        );
        monthlyListeners.value = {
          countries: data.xAxis,
          followers: data.yAxis,
        };
        loading.value = false;
      } catch (e) {
        console.error(e);
      }
    }

    const fetchNewData = async (days) => {
      await getAirplayOverTime(route.params.uuid, days, days === 12);
    };

    return { monthlyListeners, loading, fetchNewData };
  },
};
</script>

<style scoped></style>
